import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectOrdenTrabajoSubsis (Vue, filter, search, sorter, page, idordenTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idorden_trabajo', idordenTrabajo)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
        .addILike('codigo', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call(
      'ordenTrabajoSubsis.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectOrdenTrabajoSubsisRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idorden_trabajo_subsis', pks)
    const resp = await Vue.$api.call('ordenTrabajoSubsis.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
